import * as React from 'react';
import Seo from '@components/seo';

const NotFoundPage = () => (
        <>
                <Seo title='404: Not found' />
                <h1>404: Nem található az oldal</h1>
        </>
)

export default NotFoundPage
