import Prism from 'prismjs';
import React, { useEffect } from 'react';
import 'prismjs/themes/prism-coy.css';
import 'prismjs/components/prism-bash';

const Code = ({
        children,
}) => {
        useEffect(() => {
                Prism.highlightAll()
        }, [])
        return (
                <div>
                        <pre>
                                <code className="language-bash">
                                        {children}
                                </code>
                        </pre>
                </div>
        )
};

export default Code;
