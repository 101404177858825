import React from 'react'

const Caption = ({
        children,
}) => {
        return (
                <div className="u-text-align-center u-caption">
                        {children}
                </div>
        )
};

export default Caption;
