exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-eszkozok-sorbarendezo-js": () => import("./../../../src/pages/eszkozok/sorbarendezo.js" /* webpackChunkName: "component---src-pages-eszkozok-sorbarendezo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-terkeprendszerek-egyseges-orszagos-terkeprendszer-[page]-js": () => import("./../../../src/pages/terkeprendszerek/egyseges-orszagos-terkeprendszer/[page].js" /* webpackChunkName: "component---src-pages-terkeprendszerek-egyseges-orszagos-terkeprendszer-[page]-js" */),
  "component---src-templates-blog-year-summary-js": () => import("./../../../src/templates/blog-year-summary.js" /* webpackChunkName: "component---src-templates-blog-year-summary-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

