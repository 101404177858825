import page from './page';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromExtent } from 'ol/geom/Polygon';
import excludedPages from './100e-not-in-hungary';

const eovExtent = [384000, 32000, 960000, 384000];

const calculateExtentMinValue = (
        eovExtentValue,
        mapExtentValue,
        pageSize,
) => {
        if (
                eovExtentValue > mapExtentValue
        ) {
                return eovExtentValue;
        }
        else {
               return (
                       eovExtentValue +
                       Math.floor(
                                (
                                       mapExtentValue -
                                       eovExtentValue
                                ) / pageSize
                        ) * pageSize
               );
        }
};

const calculateExtentMaxValue = (
        eovExtentValue,
        mapExtentValue,
        pageSize,
) => {
        if (
                eovExtentValue < mapExtentValue
        ) {
                return eovExtentValue;
        }
        else {
               return (
                        eovExtentValue -
                        Math.floor(
                               (
                                       eovExtentValue -
                                       mapExtentValue
                                ) / pageSize
                        ) * pageSize
                );
        }
}


const calculateExtent = (
        { width, height },
        eovExtent,
        mapExtent,
) => {
        const xmin = calculateExtentMinValue(
                eovExtent[0],
                mapExtent[0],
                width,
        );
        const ymin = calculateExtentMinValue(
                eovExtent[1],
                mapExtent[1],
                height,
        );
        const xmax = calculateExtentMaxValue(
                eovExtent[2],
                mapExtent[2],
                width,
        );
        const ymax = calculateExtentMaxValue(
                eovExtent[3],
                mapExtent[3],
                height,
        );
        return [xmin, ymin, xmax, ymax];
};

const calculateLabel = (scale, x, y) => {
        let pageScales = [];
        let label = [];
        for (let pageScale in page) {
                pageScales.push(+pageScale);
        }
        pageScales.sort((a, b) => b - a);
        for (let pageScale of pageScales) {
                const {width, height} = page[pageScale];
                switch (pageScale) {
                        case 100000:
                                label.push(`${Math.floor((y - eovExtent[1]) / height)}${Math.floor((x - eovExtent[0]) / width)}`);
                                break;
                        case 50000:
                        case 4000:
                                label.push('-')
                                // eslint-disable-next-line no-fallthrough
                        default:
                                const posX = !!(Math.floor((x / width)) % 2);
                                const posY = !!(Math.floor((y / height)) % 2);
                                if (
                                        posX === false &&
                                        posY === false
                                ) {
                                        label.push(3);
                                }
                                else if(
                                        posX === true &&
                                        posY === false
                                ) {
                                        label.push(4);
                                }
                                else if(
                                        posX === false &&
                                        posY === true
                                ) {
                                        label.push(1);
                                }
                                else if(
                                        posX === true &&
                                        posY === true
                                ) {
                                        label.push(2);
                                }
                                break;
                }
                if (scale === pageScale) {
                        break;
                }
        }
        return label.join('');
};

const calculateFeatures = (
        [xmin, ymin, xmax, ymax],
        { width, height, scale },
) => {
        let polygons = [];
        let x = xmin;
        while (x < xmax) {
                let y = ymin;
                while (y < ymax) {
                        const name = calculateLabel(scale, x, y);

                        if (
                                !excludedPages
                                        .filter(
                                                ep => name.split('-')[0] === ep
                                        ).length
                        ) {
                                polygons.push({
                                        scale,
                                        name: calculateLabel(scale, x, y),
                                        coordinates: [x, y, x + width, y + height],
                                });
                        }
                        y += height;
                }
                x += width;
        }
        return polygons;
}

const calculateFeatureLayer = (
        eovExtent,
        extent,
        page,
) => {
        const calculatedExtent = calculateExtent(
                page,
                eovExtent,
                extent || eovExtent,
        );

        return calculateFeatures(
                calculatedExtent,
                page,
        );
};

export const getFeatures = (state={}) => {
        const { extent, scale } = state;
        let features = []
        for (let { coordinates, name, } of getEotrLayer(extent, scale)) {
                const geometry = fromExtent(coordinates).transform(
                        'EPSG:23700',
                        'EPSG:3857'
                );
                const geometryExtent = geometry.getExtent();
                features.push(
                        new Feature({
                                labelPoint: new Point([
                                        (
                                                geometryExtent[2] -
                                                geometryExtent[0]
                                        ) / 2,
                                        (
                                                geometryExtent[3] -
                                                geometryExtent[1]
                                        ) / 2,
                                ]),
                                geometry,
                                name,
                        })
                );
        }
        return features;
};

export const getEotrLayer = (extent, scale) => {
	if (
		typeof extent === 'undefined' ||
		typeof scale === 'undefined'
	) {
		return [];
	}
	let displayScaleRangeValues = [];
	for (let s in page) {
		const { min, max } = page[s].displayScaleRangeValue;
		if (
			min < scale &&
			max >= scale
		) {
			displayScaleRangeValues.push(s);
		}
	}
	return displayScaleRangeValues.reduce((acc, s) => (
		[...acc, ...calculateFeatureLayer(
			eovExtent,
			extent,
			page[s],
		)]
        ), []);
}
