import Lead from '@components/articles/lead';
import Code from '@components/articles/code';
import Caption from '@components/articles/caption';
import ExampleMap from '@components/articles/example-map';

export const getMdxProviderComponents = () => {

        return {
                lead: Lead,
                code: Code,
                caption: Caption,
                examplemap: ExampleMap,
        };
};
