const page = {
        100000: {
                scale: 100000,
                width: 48000,
                height: 32000,
                displayScaleRangeValue: {
                        max: 9999999999,
                        min: 300000,
                }
        },
        50000: {
                scale: 50000,
                width: 24000,
                height: 16000,
                displayScaleRangeValue: {
                        max: 300000,
                        min: 150000,
                }
        },
        25000: {
                scale: 25000,
                width: 12000,
                height: 8000,
                displayScaleRangeValue: {
                        max: 0,
                        min: 0,
                }
        },
        10000: {
                scale: 10000,
                width: 6000,
                height: 4000,
                displayScaleRangeValue: {
                        max: 150000,
                        min: 10000,
                }
        },
        4000: {
                scale: 4000,
                width: 3000,
                height: 2000,
                displayScaleRangeValue: {
                        max: 0,
                        min: 0,
                }
        },
        2000: {
                scale: 2000,
                width: 1500,
                height: 1000,
                displayScaleRangeValue: {
                        max: 0,
                        min: 0,
                }
        },
        1000: {
                scale: 1000,
                width: 750,
                height: 500,
                displayScaleRangeValue: {
                        max: 10000,
                        min: 0,
                }
        },
        500: {
                scale: 500,
                width: 375,
                height: 250,
                displayScaleRangeValue: {
                        max: 0,
                        min: 0,
                }
        },
};

export default page;
