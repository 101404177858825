import React from 'react'
// import PropTypes from 'prop-types'

const Lead = ({children}) => {
        return (
                <p className="lead">
                        {children}
                </p>
        )
}

Lead.propTypes = {

}

export default Lead
