import React from 'react';
import PropTypes from 'prop-types';
import MapLayout from '@layouts/map';
import Eotr from '@components/maps/map-system/eotr/eotr';

const EotrPage = ({params}) => {
        return (
                <MapLayout>
                        <Eotr params={params}></Eotr>
                </MapLayout>
        );
}

EotrPage.propTypes = {
        params: PropTypes.shape({
                page: PropTypes.string.isRequired,
        }),
};

EotrPage.defaultProps = {
        params: {
                page: 'kereso',
        },
};

export default EotrPage
