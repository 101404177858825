import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Hero = ({
        name,
        cover,
        socialIconVisibility,
}) => {
        const $cover = cover ?
                 <GatsbyImage placeholder="none" image={getImage(cover.image)} alt="" width={320} height={320} /> :
                 <StaticImage placeholder="none" src="../../images/iceland-2-2.jpg" alt="Fa" height={320} />;
        return (
                <div className="hero">
                        {$cover}
                        <div className="hero-message">
                                <h1 dangerouslySetInnerHTML={{__html: name}}></h1>
                        </div>
                        {socialIconVisibility && <div className="hero-social">
                                <a href="https://github.com/bszabo28" target="_blank" rel="noreferrer">
                                        <StaticImage placeholder="none" src="../../images/octocat.png" alt="Fa" width={32} height={32} />
                                </a>
                                <a href="https://codepen.io/szabobalazs" target="_blank" rel="noreferrer">
                                        <StaticImage placeholder="none" src="../../images/codepen.png" alt="Fa" width={32} height={32} />
                                </a>
                        </div>}
                </div>
        )
};

Hero.defaultProps = {
        name: `Okleveles <br/> terület- és településfejlesztő <br/> geográfus`,
        socialIconVisibility: false,
}

export default Hero
