import React from 'react';
import { graphql } from 'gatsby';
import Seo from '@components/seo';
import Cookie from '@containers/cookie';
import Hero from '@components/hero/hero';
import NormalLayout from '@layouts/normal';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import MainFooter from '@components/footer/main-footer';

const PageTemplate = ({
        data: {
                page: {name, cover, childMdx},
        },
}) => {
        return (
                <>
                        <Seo title={name} image={cover?.image?.childImageSharp?.original?.src} />
                        <NormalLayout>
                                <Hero name={name.split(' ').join('<br>')} cover={cover}></Hero>
                                <article>
                                        <MDXRenderer>{childMdx.body}</MDXRenderer>
                                </article>
                                <Cookie></Cookie>
                                <MainFooter></MainFooter>
                        </NormalLayout>
                </>
        )
};

const pageQuery = graphql`
        query Page($path: String!) {
                page: googleDocs(slug: { eq: $path }) {
                        name
                        description
                        cover {
                                image {
                                        childImageSharp {
                                                gatsbyImageData(
                                                        width: 320
                                                        height: 320
                                                        placeholder: NONE
                                                        formats: [AUTO, WEBP]
                                                )
                                                original {
                                                        src
                                                }
                                        }
                                }
                        }
                        childMdx {
                                body
                        }
                }
        }
`;

export { pageQuery }
export default PageTemplate;
