import React from 'react';
import { graphql } from 'gatsby';
import Seo from '@components/seo';
import Cookie from '@containers/cookie';
import Hero from '@components/hero/hero';
import List from '@components/list/list';
import NormalLayout from '@layouts/normal';
import MainFooter from '@components/footer/main-footer';
import TaxnumberValidator from '@components/tax-number';
import FeaturedImagesContainer from '@containers/featured-images';

const IndexPage = ({
        data: {
                blogPosts,
                references,
        }
}) => {
        const img = blogPosts.edges[0]?.node?.cover?.image?.childImageSharp?.original?.src;

        return (
                <NormalLayout hasMdxContent={false}>
                        <Seo title="Főoldal" description="Szabó Balázs weboldala." image={img} />
                        <Hero socialIconVisibility={true}></Hero>
                        <section className="section">
                                <h2>Blog</h2>
                                <div className="section-content">
                                        <p>
                                                Ebben a blokkban a földrajzzal, pontosabban térinformatikával kapcsolatos bejegyzéseket találsz.
                                        </p>
                                        <hr/>
                                        <List data={blogPosts} className="blog-posts two-column-layout"></List>
                                </div>
                        </section>
                        <section className="section">
                                <h2>Munkáim</h2>
                                <div className="section-content">
                                        <p>
                                                Írj bátran: <a href="mailto:mail@szabo-balazs.hu">mail@szabo-balazs.hu</a>
                                        </p>
                                        <hr/>
                                        <List data={references} className="references"></List>
                                </div>
                        </section>
                        <FeaturedImagesContainer></FeaturedImagesContainer>
                        <TaxnumberValidator></TaxnumberValidator>
                        <Cookie></Cookie>
                        <MainFooter></MainFooter>
                </NormalLayout>
        );
}

const pageQuery = graphql`
        query Index {
                blogPosts: allGoogleDocs(
                        filter: { slug: { regex: "/blog/" } }
                        sort: { fields: createdTime , order: DESC }
                        limit: 8
                ) {
                        edges {
                                node {
                                        id
                                        slug
                                        name
                                        tags
                                        description
                                        createdTime
                                        cover {
                                                image {
                                                        childImageSharp {
                                                                gatsbyImageData(
                                                                        width: 200
                                                                        height: 200
                                                                        placeholder: NONE
                                                                        formats: [AUTO, WEBP]
                                                                )
                                                                original {
                                                                        src
                                                                }
                                                        }
                                                }
                                        }
                                }
                        }
                }
                references: allGoogleDocs(
                        filter: { slug: { regex: "/munkaim/" } }
                        sort: {fields: breadcrumb___name}
                ) {
                        edges {
                                node {
                                        id
                                        name
                                        tags
                                        description
                                        cover {
                                                image {
                                                        childImageSharp {
                                                                gatsbyImageData(
                                                                        width: 200
                                                                        height: 200
                                                                        placeholder: NONE
                                                                        formats: [AUTO, WEBP]
                                                                )
                                                        }
                                                }
                                        }
                                        childMdx {
                                                body
                                        }
                                }
                        }
                }
        }
`;

export { pageQuery }
export default IndexPage
