/* eslint-disable */
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import Slider from 'rc-slider';
import OSM from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ';
import 'rc-slider/assets/index.css';
import TileLayer from 'ol/layer/Tile';
import { getRenderPixel } from 'ol/render';
import SwipeIcon from '@images/icons/swipe.svg';
import { noModifierKeys } from 'ol/events/condition';
import React, { useEffect, useState, useRef } from 'react';
import { DragPan, MouseWheelZoom, defaults } from 'ol/interaction';

import PropTypes from 'prop-types';

const Icon = props => {
        const style = {
                position: 'absolute',
                left: `${props.offset}%`,
                transform: 'translate(-50%, -50%)',
                width: '4rem',
                height: '4rem',
        };
        return (
                <div style={style} className={props.className}>
                        <SwipeIcon></SwipeIcon>
                </div>
        );
}


const ExampleMap = ({
        className,
        layers,
        view,
}) => {

        let classNames = ['example-map'].concat(className.split(' '));
        const $el = useRef(null);
        const swipeRef = useRef(null);
        const [map, setMap] = useState();
        const [mapVisibility, setMapVisibility] = useState(false);
        const [overlay, setOverlay] = useState();

        const sliderOnChangeHandler = value => {
                swipeRef.current = value;
                map && map.render();
        };

        const overlayPrerender = (event) => {
                const ctx = event.context;
                if (map) {
                        const swipe = swipeRef.current === null ? 50 : swipeRef.current;
                        const mapSize = map.getSize();
                        const width = mapSize[0] * (swipe / 100);
                        const tl = getRenderPixel(event, [width, 0]);
                        const tr = getRenderPixel(event, [mapSize[0], 0]);
                        const bl = getRenderPixel(event, [width, mapSize[1]]);
                        const br = getRenderPixel(event, mapSize);
                        ctx.save();
                        ctx.beginPath();
                        ctx.moveTo(tl[0], tl[1]);
                        ctx.lineTo(bl[0], bl[1]);
                        ctx.lineTo(br[0], br[1]);
                        ctx.lineTo(tr[0], tr[1]);
                        ctx.closePath();
                        ctx.clip();
                }
        };

        const postRender = event => {
                const ctx = event.context;
                ctx.restore();
        };

        useEffect(() => {
                if (overlay && map) {
                        overlay.on('prerender', overlayPrerender);
                        overlay.on('postrender', postRender);
                }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [overlay, map])

        useEffect(() => {
                if ($el.current && !map) {
                        const osm = new TileLayer({
                                source: new OSM(),
                        });
                        const overlay = new TileLayer({
                                source: new XYZ({
                                        urls:[
                                                'https://tile-server.szabo-balazs.hu/tileserver.php?/index.json?/MBTiles/hidvegi-to-1965/{z}/{x}/{y}.png'
                                        ],
                                        minZoom: 12,
                                        maxZoom: 16,
                                        tilePixelRatio: 1,
                                })
                        });
                        const defaultView = new View({
                                center: [1910000,5880000],
                                zoom: 13,
                                minZoom: 12,
                                maxZoom: 16,
                        });
                        const map = new Map({
                                interactions: defaults({dragPan: false, mouseWheelZoom: false}).extend([
                                        new DragPan({
                                                condition: function (event) {
                                                        return this.getPointerCount() === 2 || noModifierKeys(event);
                                                },
                                        }),
                                        new MouseWheelZoom({
                                                condition: noModifierKeys,
                                        }) ]
                                ),
                                layers: layers || [osm, overlay],
                                view: view ||  defaultView,
                                target: $el.current,
                        });
                        setOverlay(overlay);
                        setMap(map);
                }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [$el]);

        let noClickClasses = ['example-map__noclick', 'noclick'];
        if (mapVisibility) {
                noClickClasses.push('hidden');
        }

        return (
                <div className={classNames.join(' ')}>
                        <div className="example-map__map">
                                <div ref={$el} className="map-pane"></div>
                                <div className="example-map__slider" onMouseDown={e => e.preventDefault()}>
                                        <Slider
                                                onMouseDown={e => e.preventDefault()}
                                                onChange={sliderOnChangeHandler}
                                                defaultValue={50}
                                                handle={Icon}
                                        />
                                </div>
                                <div className={noClickClasses.join(' ')} onClick={() => setMapVisibility(true)} >
                                        <button>A térkép aktiválásához kattints ide</button>
                                </div>
                        </div>
                        <p>
                                Hídvégi-tó helye 1965-ben a <a href="https://www.fentrol.hu/hu/">fentrol.hu</a> légifotóin
                                <br/>
                                <i>
                                      (A csúszkát jobbra, vagy balra húzva változtatható a réteg fedése)
                                </i>
                        </p>
                </div>
        );
};

ExampleMap.defaultProps = {
        className               : '',
};

ExampleMap.propTypes = {
        className               : PropTypes.string,
        layers                  : PropTypes.array,
};

export default ExampleMap;
