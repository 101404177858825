import React from 'react';
import { graphql } from 'gatsby';
import Seo from '@components/seo';
import Cookie from '@containers/cookie';
import Hero from '@components/hero/hero';
import List from '@components/list/list';
import NormalLayout from '@layouts/normal';
import MainFooter from '@components/footer/main-footer';

const BlogYearTemplate = ({
        data: {
                postsByYear
        },
        pageContext: {
                gt,
                lt
        }
}) => {

        const img = postsByYear.edges[0]?.node?.cover?.image?.childImageSharp?.original?.src;
        return (
                <>
                        <NormalLayout>
                                <Seo title={`${gt} - ${lt}`} description="Szabó Balázs weboldala." image={img} />
                                <Hero socialIconVisibility={true}></Hero>
                                <section className="section">
                                        <h2>{gt}</h2>
                                        <div className="section-content">
                                                <List data={postsByYear} className="blog-posts two-column-layout"></List>
                                        </div>
                                </section>
                                <Cookie></Cookie>
                                <MainFooter></MainFooter>
                        </NormalLayout>
                </>
        )
}

const pageQuery = graphql`
        query BlogYear ($gt: Date!, $lt: Date!) {
                postsByYear: allGoogleDocs(
                        filter: {
                                slug: { regex: "/blog/" }
                                createdTime: { gt: $gt, lt: $lt }
                        }
                        sort: { fields: createdTime, order: DESC }
                ) {
                        edges {
                                node {
                                        id
                                        slug
                                        name
                                        tags
                                        description
                                        createdTime
                                        cover {
                                                image {
                                                        childImageSharp {
                                                                gatsbyImageData(
                                                                        width: 200
                                                                        height: 200
                                                                        placeholder: NONE
                                                                        formats: [
                                                                                AUTO
                                                                                WEBP
                                                                        ]
                                                                )
                                                                original {
                                                                        src
                                                                }
                                                        }
                                                }
                                        }
                                }
                        }
                }
        }
`

export { pageQuery }
export default BlogYearTemplate
