/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
require("normalize.css")
require("./src/styles/style.scss")
const React = require("react")
const { PageProvider } = require("./src/context-api/page/provider")

exports.wrapRootElement = ({ element }) => (
        <React.StrictMode>
                <PageProvider>{element}</PageProvider>
        </React.StrictMode>
)

exports.onRouteUpdate = () => {
        if (process.env.NODE_ENV !== 'development') {
                navigator && navigator.serviceWorker &&
                navigator.serviceWorker.register("/sw.js").then(reg => {
                        reg.update()
                })
        }
};

exports.onServiceWorkerUpdateReady = () => {
        if (process.env.NODE_ENV !== 'development') {
                window.location.reload();
        }
};
