import React from 'react';
// import { PropTypes } from 'prop-types';
import Branding from '@components/header/branding';

const HeaderComponent = ({
        brandingText,
        brandingOnClick,
}) => {

        return (
                <header className="main-header">
                        <nav className="container primary-navigation">
                                <Branding
                                        brandingText={brandingText}
                                        onClick={brandingOnClick}
                                ></Branding>
                        </nav>
                </header>
        );
};

HeaderComponent.defaultProps = {
        brandingText: 'Szabó Balázs'
};
HeaderComponent.propTypes = {};

export default HeaderComponent;
