import React from 'react'
import { Link } from 'gatsby';

const Branding = ({
        brandingText,
        onClick,
}) => {
        return (
                <div className="branding">
                        <Link onClick={ onClick } to="/">
				{ brandingText.split(' ').map((t, i) => (<span key={i}>{t}</span>)) }
			</Link>
                </div>
        );
};

Branding.defaultProps = {
        onClick: () => {},
        brandingText: '',
};

export default Branding;
