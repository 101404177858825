// import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import Validator from '@helpers/tax-number/validator';

const TaxnumberValidator = props => {

        const validator = useRef(null);
        const [state, setState] = useState({
                errors: undefined,
                valid: undefined,
                cvs: undefined,
                formatted: undefined,
                main: undefined,
                region: undefined,
                regionName: undefined,
                vat: undefined,
                vatName: undefined,
        });
        if (!validator.current) {
                validator.current = new Validator();
        }

        const validate = ({target}) => {
                const results = validator.current.check(target.value.replace(/[^0-9]+/g, ''));
                setState(results);
        }

        let errors;
        if (state.errors) {
                errors = state.errors.map((error, index) => (<div key={index} className="field__error">{error}</div>))
        }
        let message;
        let sectionClassNames = ['tax-number-validator', 'section'];
        if (state.valid) {
                sectionClassNames.push('is-valid');
                message = (<div className="field__message">
                        {[state.regionName, state.vatName].map(e => <div>{e}</div>)}
                </div>);
        }
        else if (!state.valid && state.formatted) {
                sectionClassNames.push('is-invalid');
        }


        return (
                <section className={sectionClassNames.join(' ')}>
                        <div className='container'>
                                <div className='field'>
                                        <label htmlFor='tax-number-validator' className='field__label'>Adószám formai ellenőrzése <a className="link" href="https://bszabo28.github.io/adoszam-ellenorzo/index.html" target="_blank" rel="noopener noreferrer">(teljes verzió)</a></label>
                                        <input id='tax-number-validator' className='field__input' type='number' pattern="\d*" onInput={validate}/>
                                        {message}
                                        {errors}
                                </div>
                        </div>
                </section>
        );
};

TaxnumberValidator.propTypes = {

};

export default TaxnumberValidator;
