import React from 'react'
import PropTypes from 'prop-types'
import NorthIcon from '@images/icons/north.svg'

const JumpUp = ({
        isVisible,
        onClick
}) => {
        let classNames = ['jump-up']
        if (isVisible) {
              classNames.push('active')
        }
        return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div
                        className={classNames.join(' ')}
                        onClick={onClick}
                >
                        <NorthIcon onClick={onClick} />
                </div>
        )
}

JumpUp.propTypes = {
  isVisible: PropTypes.bool,
}

JumpUp.defaultProps = {
  isVisible: false,
}

export default JumpUp
