import { createContext } from 'react';

const PageContext = createContext({
        scrollUp: undefined,
        $scrollContainer: undefined,
        setScrollContainer: undefined,

});

export {
        PageContext
};
