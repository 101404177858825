import React, { useState }  from 'react';
import { PageContext } from '@context-api/page/context';

const PageProvider = ({children}) => {

        const setScrollContainer = $scrollContainer => {
                setPage(prevState => ({
                        ...prevState,
                        $scrollContainer,
                        scrollUp: () => $scrollContainer.scroll({ top: 0, left: 0, behavior: 'smooth' }),
                }));
        };

        const initialState = {
                setScrollContainer,
                scrollUp: undefined,
                $scrollContainer: undefined,
        };
        const [ page, setPage ] = useState(initialState);

        return (
                <PageContext.Provider value={page}>{children}</PageContext.Provider>
        );
}

export {
        PageProvider
};
