import React, { useState, useRef } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Sorter = () => {
        const el = useRef(null);
        const [textarea, setTextarea] = useState([]);
        const [copyToClipboard, setCopyToClipboard] = useState(false);
        const [removeDuplications, setRemoveDuplication] = useState(true);
        const [whiteSpace, setWhiteSpace] = useState(true);

        const handleTextarea = ({value}) => {
                const lines = value.split(/\n/);
                const isKeyValuePair = lines.map(line => line.indexOf(':') > -1).length;
                let nextLines = lines
                        .sort(function (a, b) {
                                if (isKeyValuePair) {
                                        let aValue = (a || '').replace(/\s/g, '').split(/:/);
                                        let bValue = (b || '').replace(/\s/g, '').split(/:/);
                                        aValue.length = bValue.length = 2;
                                        aValue = aValue.pop();
                                        bValue = bValue.pop();
                                        return (aValue || a).localeCompare((bValue || b));
                                }
                                else {
                                        console.log('B');
                                        return a.localeCompare(b);
                                }
                        })
                        .filter(i => !!i);

                if (whiteSpace) {
                        nextLines = nextLines.map(line => {
                                return line
                                        .replace(/[\s]*:[\s]*/g, ' : ');
                        });
                }

                if (removeDuplications) {
                        nextLines = nextLines.filter(function(item, pos) {
                                return nextLines.indexOf(item) === pos;
                        });
                }
                setCopyToClipboard(false);
                setTextarea(nextLines);
        };

        const update = () => {
                setTimeout(() => {
                        const event = new Event('input', { bubbles: true});
                        el && el.current && el.current.dispatchEvent(event);
                });
        };

        const handleDuplications = e => {
                setRemoveDuplication(e.currentTarget.checked);
                update();
        };

        const handleWhiteSpaces = e => {
                setWhiteSpace(e.currentTarget.checked)
                update();
        };

        const result = textarea.length ? (
                <div className="field">
                        <label htmlFor="sorter-textarea-output" className="field__label">Kimenet:</label>
                        <CopyToClipboard text={textarea.join('\n')} onCopy={() => setCopyToClipboard(true)}>
                                <div className={copyToClipboard ? 'copy-to-clipboard copied' : 'copy-to-clipboard'}>
                                        <span className="success">Vágólapra másolva</span>
                                        <span className="standard">Másolja a vágólapra</span>
                                        <ul>
                                                {(textarea).map((t, key) => (<li key={key}>{t}</li>))}
                                        </ul>
                                </div>
                        </CopyToClipboard>
                </div>
        ) : (<></>);

        return (<div className="form sorter">
                <div className="field">
                        <input checked={removeDuplications} id="removing-duplicates" onChange={handleDuplications} type="checkbox" className="field__checkbox"></input>
                        <label htmlFor="removing-duplicates" className="field__label">Duplikált sorok szűrése</label>
                </div>
                <div className="field">
                        <input checked={whiteSpace} id="has-white-spaces" onChange={handleWhiteSpaces} type="checkbox" className="field__checkbox"></input>
                        <label htmlFor="has-white-spaces" className="field__label">Kettősponttól jobbra és balra legyen üres sor</label>
                </div>
                <div className="field">
                        <label htmlFor="sorter-textarea-input" className="field__label">Beviteli mező:</label>
                        <textarea ref={el} onInput={({currentTarget}) => handleTextarea(currentTarget)} id="sorter-textarea-input" className="field__textarea" rows="5"></textarea>
                </div>
                {result}
        </div>);
};

export {
        Sorter,
}
