import { PropTypes } from 'prop-types';
import JumpUp from '@components/jump-up';
import { MDXProvider } from '@mdx-js/react'
import Header from '@components/header/header';
import { PageContext } from '@context-api/page/context';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { getMdxProviderComponents } from '@helpers/get-mdx-provider-components';

const NormalLayout = ({
        hasMdxContent,
        children,
}) => {
        const $container = useRef(null);
        const requestAnimationFrameRef = useRef(null);
        const { setScrollContainer } = useContext(PageContext);
        const [state, setState] = useState({
		sticky: false,
		scrollDelta: 5,
		lastScrollTop: 0,
		didScrolled: false,
		scrollDirection: undefined,
	});
        const scrollEventHandler = ({target}) => {

		setState(prevState => ({
			...prevState,
			didScrolled: true,
		}));

	};
        const scrollUp = (e) => {
                if ($container.current) {
                        $container.current.scroll({ top: 0, left: 0, behavior: 'smooth' });
                }
        };
        const headerClassNames = [
		'page-container',
		state.sticky && 'sticky',
		state.scrollDirection,
		state.collapsable && 'collapsable',
	];
        const $content = (
                <>
                        <Header brandingOnClick={scrollUp}></Header>
                        <div className="page-content container">
                                {children}
                        </div>
                </>
        );
        const $jumpUp = (<JumpUp isVisible={state.sticky} onClick={scrollUp} />);
        const $toRender = (
                <div className={headerClassNames.filter(c => !!c).join(' ')} ref={$container}>
                        {$content}
                        {$jumpUp}
                </div>
        );
        useEffect(() => {
                        $container.current.onscroll = scrollEventHandler;
                        setScrollContainer($container.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [$container]);
        useEffect(() => {
                if (state.didScrolled) {

                        if (typeof window !== 'undefined') {
                                if (requestAnimationFrameRef.current) {
                                        window.cancelAnimationFrame(
                                                requestAnimationFrame.current,
                                        );
                                }
                                requestAnimationFrameRef.current = (
                                        window.requestAnimationFrame(() => {
                                                const { current } = $container;
                                                const { scrollTop, offsetHeight, scrollHeight } = current;
                                                const scrollTopMax = scrollHeight - offsetHeight;
                                                const delta = Math.abs(state.lastScrollTop - scrollTop);
                                                if (state.delta > delta) {
                                                        setState(prevState => ({
                                                                ...prevState,
                                                                didScrolled: false,
                                                        }));
                                                }
                                                else {
                                                        setState(prevState => ({
                                                                ...prevState,
                                                                didScrolled: false,
                                                                sticky: scrollTop > 150,
                                                                lastScrollTop: scrollTop,
                                                                collapsable: scrollTop > 200,
                                                                scrollDirection: prevState.lastScrollTop < scrollTop
                                                                        ? scrollTopMax === scrollTop ? 'up' : 'down'
                                                                        : 'up',
                                                        }));
                                                }
                                        })
                                );
                        }
                }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [state.didScrolled]);
        if (hasMdxContent) {
                return (
                        <MDXProvider components={getMdxProviderComponents()}>
                                {$toRender}
                        </MDXProvider>
                );
        }
        else {
                return ($toRender);
        }

};

NormalLayout.defaultProps = {
        hasMdxContent: true,
};
NormalLayout.propTypes = {
        hasMdxContent: PropTypes.bool,
        children: PropTypes.oneOfType([
                PropTypes.arrayOf(PropTypes.element),
                PropTypes.element,
        ]),
};

export default NormalLayout;
