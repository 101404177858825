import React from 'react';
import Seo from '@components/seo';
import Cookie from '@containers/cookie';
import Hero from '@components/hero/hero';
import NormalLayout from '@layouts/normal';
import { Sorter } from '@components/tools/sorter';
import MainFooter from '@components/footer/main-footer';

const SorterPage = () => {
        return (
                <NormalLayout>
                        <Seo title="Szöveges mező sorainak sorbarendezése" />
                        <Hero name="Szöveges mező sorainak sorbarendezése" socialIconVisibility={true}></Hero>
                        <article className="article">
                                <div className="container">
                                        <div>Wordpress, Drupal kulcs érték listáinak sorbarendezésére használom.</div>
                                        <strong>A sorbarendetés érték alapján történik (kettősponttól jobbra van az érték)</strong>
				</div>
                                <br></br>
                                <div className="section__content">
                                        <Sorter></Sorter>
                                </div>
                        </article>
                        <Cookie></Cookie>
                        <MainFooter></MainFooter>
                </NormalLayout>
        );
}

SorterPage.propTypes = {

}

export default SorterPage
