import React from 'react';
import { Link } from 'gatsby';
// import PropTypes from 'prop-types';
import UrlIcon from '@images/icons/url.svg';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const ListItem = ({
        data
}) => {
        const { node } = data;
        const { slug, name, cover, tags, childMdx, description } = node;
        const meta = description && description
                .split('\n')
                .map(el => el.split(':').map(s => s.trim()))
                .reduce((acc, i) => {
                        if (i[0] === 'lead') {
                                acc[i[0]] = (<p className="lead">{i[1]}</p>);
                        }
                        else {
                                acc[i[0]] = i[1];
                        }
                        return acc;
                },{});
        const $cover = cover && <GatsbyImage placeholder="none" image={getImage(cover.image)} alt="" />;
        const $tags = (tags || (meta?.tags || '').replace(/(\[|\])/g, '').split(',')
                .filter(tag => !!tag)
                .map(e=>e.trim())).map((b, key) => (<span key={key} className='tag'>{b}</span>));
        const $lead = meta && meta.lead ? meta.lead : childMdx ? (<MDXRenderer>{childMdx.body}</MDXRenderer>) : '';
        const $web = meta && meta.web && (
                <a href={`https://${meta.web}`} target='__blank'>
                        <UrlIcon></UrlIcon>
                        <span>URL</span>
                </a>
        );
        const $content = (
                <div className="content">
                        <div className="cover">
                                {$cover}
                        </div>
                        <header>
                                <h3 className="title">{name}</h3>
                                {$lead}
                                <div className='tags'>
                                        { $tags }
                                </div>
                                <div className="link">
                                        {$web}
                                </div>
                        </header>
                </div>
        );
        if (slug) {
                return (
                        <li className="has-link">
                                <Link to={slug}>
                                        {$content}
                                </Link>
                        </li>
                );
        }
        else {
                return (
                        <li>
                                {$content}
                        </li>
                );
        }
};

ListItem.propTypes = {

};

export default ListItem;
