import React from 'react'
// import PropTypes from 'prop-types'
import ListItem from '@components/list/list-item';

const List = ({
        data,
        ...props
}) => {
        const { edges } = data;
        const $edges = Array.isArray(edges) ?
                edges.map(
                        (edge, key) =>
                        <ListItem data={edge} key={key}></ListItem>
                ) : undefined;
        return (
                <ul {...props}>
                        {$edges}
                </ul>
        );
};

List.propTypes = {

};

export default List;
