import React, { useState, useLayoutEffect } from 'react'
import { Link } from 'gatsby';

const Cookie = () => {

        const [isCookiePolicyUnderstood, setCookiePolicyUnderstood] = useState(
                +window.localStorage.getItem('is-cookie-policy-understood'),
        );

        const cookiePolicyClickHandler = () => {
                setCookiePolicyUnderstood(1);
        }

        useLayoutEffect(() => {
                if (isCookiePolicyUnderstood) {
                        window.localStorage.setItem('is-cookie-policy-understood', 1);
                }
        }, [isCookiePolicyUnderstood]);

        if (isCookiePolicyUnderstood) {
                return (<div></div>);
        }
        else {
                return (
                        <div className="cookie-policy">
                                <Link className="cookie-policy-link" to="/oldal/suti-tajekoztato">
                                        Az oldal sütiket használ a böngésződben! Ide kattintva megtudhatod, hogy mire és miért
                                </Link>
                                <hr></hr>
                                <div className="cookie-policy-content">
                                        <p>
                                                Az oldal használatával a sütiket elfogadod.
                                                Az alábbi gombra kattintva eltűnik ez az üzenet.
                                        </p>
                                        <br></br>
                                        <button onClick={cookiePolicyClickHandler} className="button button-primary">Rendben!</button>
                                </div>
                        </div>
                )
        }
}

export default Cookie
