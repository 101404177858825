import React, { lazy, Suspense} from 'react'
const Cookie = lazy(() => import('../components/cookie'));

const CookieContainer = () => {
        const isSSR = typeof window === 'undefined';
        return (
                <>
                        {!isSSR && <Suspense fallback={<div></div>}>
                                <Cookie></Cookie>
                        </Suspense>}
                </>
        );
}

export default CookieContainer
