import ReactDOM from 'react-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useState, useRef, useEffect } from 'react';
import SwiperCore, { Navigation, Controller } from 'swiper';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import LeftArrowIcon from '@images/icons/arrow-alt-circle-left-regular.svg';
import RightArrowIcon from '@images/icons/arrow-alt-circle-right-regular.svg';
import 'swiper/css';
import 'swiper/css/navigation';

SwiperCore.use([Navigation, Controller]);

const FeaturedImages = ({data}) => {
        const $swiper = useRef(null);
        const [controlledSwiper, setControlledSwiper] = useState(null);
        let $imageSlide = (<></>);
        let slidesPerView = 3;
        let spaceBetween = 50;
        if (typeof window !== 'undefined' && window.innerWidth < 835) {
                slidesPerView = 2;
                spaceBetween = 25;
        }
        if (data?.instagram?.edges) {
                $imageSlide = data.instagram.edges.map(({node}, index) => {
                        return (
                                <SwiperSlide key={index}>
                                        <GatsbyImage placeholder="none" image={getImage(node)} alt="" width={200} height={200} />
                                </SwiperSlide>
                        );
                })
        }

        // useEffect(() => {
        //         if ($swiper.current) {
        //                 const $prevButton = $swiper.current.querySelector('.swiper-button-prev');
        //                 const $nextButton = $swiper.current.querySelector('.swiper-button-next');
        //                 ReactDOM.render(<LeftArrowIcon />, $prevButton);
        //                 ReactDOM.render(<RightArrowIcon />, $nextButton);
        //         }
        // }, [$swiper]);

        return (
                <Swiper
                        navigation
                        ref={$swiper}
                        spaceBetween={spaceBetween}
                        slidesPerView={slidesPerView}
                        onSwiper={setControlledSwiper}
                        controller={{ control: controlledSwiper }}
                >
                        {$imageSlide}
                </Swiper>
        );
};

FeaturedImages.propTypes = {

};

export default FeaturedImages;
