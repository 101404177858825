import React from 'react';
import { Link } from 'gatsby';

const MainFooter = () => {
        return (
                <footer className="main-footer">
                        <div className="container">
                                Kapcsolat: <a href="mailto:mail@szabo-balazs.hu">mail@szabo-balazs.hu</a> | Minden jog fenntartva 2020-{(new Date()).getFullYear()}
                                <hr></hr>
                                <Link to="/oldal/suti-tajekoztato">Süti tájékoztató</Link>
                                <span className="spacer">|</span>
                                <Link to="/eszkozok/sorbarendezo">Sorbarendező</Link>
                        </div>
                </footer>
        )
}

export default MainFooter
