const pages = [
        '100',
        '101',
        '102',
        '103',
        '104',
        '105',
        '106',
        '1010',
        '1011',
        '90',
        '91',
        '92',
        '93',
        '94',
        '95',
        '911',
        '80',
        '81',
        '84',
        '70',
        '60',
        '50',
        '30',
        '20',
        '10',
        '11',
        '00',
        '01',
        '02',
        '06',
        '07',
        '08',
        '19',
        '09',
        '010',
        '011',
        '111',
        '110',
        '210',
        '211',
        '310',
        '311',
        '410',
        '411',
        '511',
        '611',
];

export default pages;
