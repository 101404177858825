import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import FeaturedImages from '@components/images/featured-images';
// import PropTypes from 'prop-types'

const FeaturedImagesContainer = () => {
        const instagramFeed = useStaticQuery(
                graphql`
                        query {
                                instagram: allFile(
                                        filter: {
                                                extension: {
                                                        regex: "/(jpg)|(jpeg)|(png)/"
                                                }
                                                relativeDirectory: {
                                                        eq: "featured-images"
                                                }
                                        }
                                        sort: {fields: name, order: ASC}
                                ) {
                                        edges {
                                                node {
                                                        childImageSharp {
                                                                gatsbyImageData(
                                                                        width: 300
                                                                        height: 300
                                                                        placeholder: NONE
                                                                        formats: [AUTO, WEBP]
                                                                )
                                                        }
                                                }
                                        }
                                }
                        }
                `
        );

        return (
                <section className="section">
                        <h2>Képeim</h2>
                        <div className="section-content">
                                <FeaturedImages data={instagramFeed}></FeaturedImages>
                        </div>
                </section>
        )
}

FeaturedImagesContainer.propTypes = {}

export default FeaturedImagesContainer
