import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import { navigate } from 'gatsby';
import VectorLayer from 'ol/layer/Vector';
import Select from 'ol/interaction/Select';
import VectorSource from 'ol/source/Vector';
import { click } from 'ol/events/condition';
import { fromExtent } from 'ol/geom/Polygon';
import { getFeatures } from '@helpers/eotr/eotr';
import {Fill, Stroke, Style, Text} from 'ol/style';
import { transform, transformExtent } from 'ol/proj';
import React, { useEffect, useRef, useState } from 'react';
import {ScaleLine, defaults as defaultControls} from 'ol/control';
import { getMapboxStreetLayer } from '@helpers/map-layers/mapbox-street';

import './register-eov';


const Eotr = ({
        params
}) => {
        const $map = useRef(null);
        const olMap = useRef(null);
        const eotrSource = useRef(null);
        const olMapSelect = useRef(null);
        const [mapState, setMapState] = useState({});
        const [page, setPage] = useState(params.page);

        useEffect(() => {
                const labelStyle = new Style({
                        text: new Text({
                                font: '1.5em Work Sans,sans-serif',
                                overflow: true,
                                fill: new Fill({
                                        color: '#000',
                                }),
                                stroke: new Stroke({
                                        color: '#fff',
                                        width: 3,
                                }),
                        }),
                })
                const polygonStyle = new Style({
                        fill: new Fill({
                                color: 'rgba(255, 255, 255, 0.3)',
                        }),
                        stroke: new Stroke({
                                color: '#2d9dc1',
                                width: 2,
                        }),
                });
                // const polygonActiveStyle = new Style({
                //         fill: new Fill({
                //                 color: 'rgba(255, 255, 255, 0.3)',
                //         }),
                //         stroke: new Stroke({
                //                 color: 'red',
                //                 width: 2,
                //         }),
                //         zIndex: 99,
                // });
                const style = [polygonStyle, labelStyle];
                const buffer = 200000;
                const mapExtent = transformExtent(
                        [
                                384000 - buffer,
                                32000 - buffer,
                                960000 + buffer,
                                384000 + buffer,
                        ],
                        'EPSG:23700',
                        'EPSG:3857'
                )
                const eotrExtent = [384000, 32000, 960000, 384000]
                eotrSource.current = new VectorSource({
                        projection: 'EPSG:23700',
                        features: getFeatures(),
                        extent: eotrExtent,
                });
                const eotrLayer = new VectorLayer({
                        source: eotrSource.current,
                        projection: 'EPSG:23700',
                        extent: eotrExtent,
                        style: (feature) => {
                                labelStyle.getText().setText(feature.get('name'));
                                return style;
                        },
                        declutter: true,
                });
                const scaleLine = new ScaleLine({
                        scaleBarText: true,
                        units: 'metric',
                        minWidth: 140,
                        text: true,
                        bar: true,
                        steps: 2,
                });
                const controls = defaultControls().extend([
                        scaleLine,
                ]);
                olMap.current = new Map({
                        controls,
                        layers: [getMapboxStreetLayer(), eotrLayer],
                        target: $map.current,
                        view: new View({
                                center: transform(
                                        [19, 47],
                                        'EPSG:4326',
                                        'EPSG:3857'
                                ),
                                extent: mapExtent,
                                maxZoom: 18,
                                zoom: 1,
                        }),
                });
                olMapSelect.current = new Select({
                        condition: click,
                        layers: [eotrLayer],
                });
                olMap.current
                        .getView()
                        .fit(
                                mapExtent,
                                olMap.current.getSize(),
                        );
                olMap.current.addInteraction(
                        olMapSelect.current,
                );
                olMap.current.on('moveend', () => {
                        const extent = olMap.current
                                .getView()
                                .calculateExtent(
                                        olMap.current.getSize()
                                );
                        const eovExtent = fromExtent(extent).transform(
                                'EPSG:3857',
                                'EPSG:23700',
                        ).getExtent();
                        const scale = scaleLine.getScaleForResolution();
                        setMapState({
                                extent: eovExtent,
                                scale,
                        });
                });
                olMapSelect.current.on(
                        'select',
                        evt => {
                                const features = evt
                                        .selected
                                        .map(
                                                f => f.getProperties()?.name
                                        );
                                setPage(
                                        features.join(',') ||
                                        'kereso'
                                );
                        },
                );

                return () => {
                        console.log('CLEAN')
                        olMap.current.setTarget(null);
                        olMap.current = null;
                };
        }, [$map]);

        useEffect(() => {
                eotrSource.current.clear();
                eotrSource.current.addFeatures(
                        getFeatures(
                                mapState
                        )
                );
        }, [mapState]);

        useEffect(() => {
                navigate(
                        '/terkeprendszerek/egyseges-orszagos-terkeprendszer/' +
                        page,
                );
        }, [page]);

        return (
                <div className='map-container'>
                        <div ref={$map}></div>
                </div>
        )
}

export default Eotr
