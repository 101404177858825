import React from 'react';
import Header from '@components/header/header';
// import PropTypes from 'prop-types'

const MapLayout = ({
        children,
}) => {
        return (
                <>
                        <Header></Header>
                        {children}
                </>
        )
};

MapLayout.defaultProps = {};

MapLayout.propTypes = {};

export default MapLayout;
