import React from 'react';
import { graphql } from 'gatsby';
import Seo from '@components/seo';
import Cookie from '@containers/cookie';
import Hero from '@components/hero/hero';
import NormalLayout from '@layouts/normal';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Breadcrumb from '@components/header/breadcrumb';
import MainFooter from '@components/footer/main-footer';

const PostTemplate = ({
        data: {
                post: {id, name, slug, cover, description, childMdx},
                site: {siteMetadata,}
        },
        path,
}) => {
        const index = path.search(/[0-9]{4}/g);
        let year = 2021;
        if (
                index > -1
        ) {
                year = path.slice(index, index + 4);
        }
        const breadcrumb = [
                {
                        id: 'blog',
                        name: 'Blog',
                        url: '/'
                },
                {
                        id: year,
                        name: year,
                        url: `/blog/${year}`,
                },
        ];
        const meta = description && description
                .split('\n')
                .map(el => el.split(':').map(s => s.trim()))
                .reduce((acc, i) => {
                        acc[i[0]] = i[1];
                        return acc;
                },{});
        return (
                <>
                        <Seo title={name} image={cover?.image?.childImageSharp?.original?.src} description={meta?.lead} />
                        <NormalLayout>
                                <Hero name={name.split(' ').join('<br>')} cover={cover} socialIconVisibility={true}></Hero>
                                <Breadcrumb route={breadcrumb} />
                                <article>
                                        <MDXRenderer>{childMdx.body}</MDXRenderer>
                                </article>
                                <Cookie></Cookie>
                                <MainFooter></MainFooter>
                        </NormalLayout>
                </>
        )
};

const pageQuery = graphql`
        query Post($path: String!) {
                site {
                        siteMetadata {
                                url
                        }
                }
                post: googleDocs(slug: { eq: $path }) {
                        id
                        name
                        slug
                        description
                        cover {
                                image {
                                        childImageSharp {
                                                gatsbyImageData(
                                                        width: 320
                                                        height: 320
                                                        placeholder: NONE
                                                        formats: [AUTO, WEBP]
                                                )
                                                original {
                                                        src
                                                }
                                        }
                                }
                        }
                        childMdx {
                                body
                        }
                }
        }
`;

export { pageQuery }
export default PostTemplate;
