/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

//  <!-- Primary Meta Tags -->
//  <title>Meta Tags — Preview, Edit and Generate</title>
//  <meta name="title" content="Meta Tags — Preview, Edit and Generate">
//  <meta name="description" content="With Meta Tags you can edit and experiment with your content then preview how your webpage will look on Google, Facebook, Twitter and more!">

//  <!-- Open Graph / Facebook -->
//  <meta property="og:type" content="website">
//  <meta property="og:url" content="https://metatags.io/">
//  <meta property="og:title" content="Meta Tags — Preview, Edit and Generate">
//  <meta property="og:description" content="With Meta Tags you can edit and experiment with your content then preview how your webpage will look on Google, Facebook, Twitter and more!">
//  <meta property="og:image" content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png">

//  <!-- Twitter -->
//  <meta property="twitter:card" content="summary_large_image">
//  <meta property="twitter:url" content="https://metatags.io/">
//  <meta property="twitter:title" content="Meta Tags — Preview, Edit and Generate">
//  <meta property="twitter:description" content="With Meta Tags you can edit and experiment with your content then preview how your webpage will look on Google, Facebook, Twitter and more!">
//  <meta property="twitter:image" content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png">


import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet-async"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, image }) {
        const { site } = useStaticQuery(
                graphql`
                        query {
                                site {
                                        siteMetadata {
                                                title
                                                description
                                                author
                                                googleSiteVerification
                                        }
                                }
                        }
                `
        )

        const metaDescription = description || site.siteMetadata.description
        const defaultTitle = site.siteMetadata?.title
        const googleSiteVerification = site.siteMetadata?.googleSiteVerification
        return (
                <Helmet
                        htmlAttributes={{
                                lang,
                        }}
                        title={title}
                        titleTemplate={
                                defaultTitle ? `%s | ${defaultTitle}` : null
                        }
                        meta={[
                                {
                                        name: `description`,
                                        content: metaDescription,
                                },
                                {
                                        property: `og:title`,
                                        content: title,
                                },
                                {
                                        property: `og:description`,
                                        content: metaDescription,
                                },
                                {
                                        property: 'google-site-verification',
                                        content: googleSiteVerification,
                                },
                                {
                                        property: `og:type`,
                                        content: `website`,
                                },
                                {
                                        name: `twitter:card`,
                                        content: `summary`,
                                },
                                {
                                        name: `twitter:creator`,
                                        content:
                                                site.siteMetadata?.author || ``,
                                },
                                {
                                        name: `twitter:title`,
                                        content: title,
                                },
                                {
                                        name: `twitter:description`,
                                        content: metaDescription,
                                },
                                {
                                        name: `twitter:url`,
                                        content: site.siteMetadata?.url,
                                },
                                {
                                        property: `og:url`,
                                        content: site.siteMetadata?.url,
                                },
                                {
                                        property: `og:image`,
                                        content: image,
                                },
                                {
                                        property: `twitter:image`,
                                        content: image,
                                },
                        ].concat(meta)}
                />
        )
}

SEO.defaultProps = {
        lang: `hu`,
        meta: [],
        description: ``,
        image: ``
}

SEO.propTypes = {
        description: PropTypes.string,
        lang: PropTypes.string,
        meta: PropTypes.arrayOf(PropTypes.object),
        title: PropTypes.string.isRequired,
}

export default SEO
