import React from 'react';
import { Link } from 'gatsby';

const Breadcrumb = ({
        route
}) => {
        const $route = route && route.map(
                ({id, name, url}) => (<Link key={id} to={url}>{name}</Link>)
        );
        return (
                <div className="breadcrumb">{$route}</div>
        );
};

Breadcrumb.defaultProps = {
        route: [],
};

export default Breadcrumb;
