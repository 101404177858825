module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-170306902-1","respectDNT":true,"anonymize":true,"head":true,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-google-docs/gatsby-browser.js'),
      options: {"plugins":[],"folder":"1vc1Gaup2YYzUAtxGcfuiFXweBVTeE4Tl","createPages":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/home/pi/szabo-balazs.hu/node_modules/gatsby-remark-unwrap-images","id":"cc481ec3-292b-5813-8135-e38644611fc3","name":"gatsby-remark-unwrap-images","version":"1.0.2","modulePath":"/home/pi/szabo-balazs.hu/node_modules/gatsby-remark-unwrap-images/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/pi/szabo-balazs.hu/node_modules/gatsby-remark-prismjs","id":"cf9e7f79-73c2-54be-9db9-a3e2896d1171","name":"gatsby-remark-prismjs","version":"6.7.0","modulePath":"/home/pi/szabo-balazs.hu/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[],"aliases":{"sh":"bash"}},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/pi/szabo-balazs.hu/node_modules/gatsby-remark-gifs","id":"57a928a3-d51c-51d6-8e1e-94d8b84fccdf","name":"gatsby-remark-gifs","version":"1.1.0","modulePath":"/home/pi/szabo-balazs.hu/node_modules/gatsby-remark-gifs/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/pi/szabo-balazs.hu/node_modules/gatsby-remark-images","id":"13368e16-22bc-5af0-925c-49e8735bb6e1","name":"gatsby-remark-images","version":"6.7.0","modulePath":"/home/pi/szabo-balazs.hu/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1200,"withWebp":true,"quality":80,"backgroundColor":"transparent","wrapperStyle":"margin-bottom: 2em;"},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/pi/szabo-balazs.hu","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"withWebp":true,"quality":80,"backgroundColor":"transparent","wrapperStyle":"margin-bottom: 2em;"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"backgroundColor":"transparent","linkImagesToOriginal":false,"markdownCaptions":false,"showCaptions":false,"withWebp":true,"maxWidth":800,"quality":100,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Szabó Balázs weboldala","short_name":"+1 weboldal","start_url":"/","background_color":"white","theme_color":"#1e4549","display":"standalone","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"75bddbd2da3fc2e7d4ad5d0ac479d019"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
