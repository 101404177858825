import MapboxVector from 'ol/layer/MapboxVector';

export const getMapboxStreetLayer = () => {
        return (
                new MapboxVector({
                        styleUrl: "mapbox://styles/mapbox/bright-v9",
                        accessToken: process.env.GATSBY_MABBOX_ACCESS_TOKEN,
                })
        );
}
